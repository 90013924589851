<template>
	<pre id="json" v-html="jsonHtml"></pre>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

export default {
	name: 'AdminInspectEntityJson',
	setup() {
		const apiStore = useApiStore()
		const adminInspectApi = apiStore.loadApi('adminInspect')
		return { adminInspectApi }
	},
	data() {
		return {
			jsonHtml: '',
		}
	},
	created() {
		this.getJson()
	},
	methods: {
		async getJson() {
			const { data } = await this.adminInspectApi.getJson(
				this.$route.params.entityType,
				this.$route.params.id_username_namePath
			)
			this.jsonHtml = data
		},
	},
}
</script>

<style lang="scss" scoped>
// github.com/center-key/pretty-print-json
#json {
	font-size: 0.13rem;
	line-height: 0.16rem;
	white-space: break-spaces;
}
// #json:deep().json-key {
// }
#json:deep().json-string {
	color: steelblue;
}
#json:deep().json-number {
	color: $bad;
}
#json:deep().json-boolean {
	color: green;
}
#json:deep().json-null {
	color: dimgray;
}
</style>
